const checkTextInputs = (selector) =>{
    try {
        const txtInputs = document.querySelectorAll(selector);
        if(txtInputs.length > 0){
            txtInputs.forEach(input =>{
            input.addEventListener(`keypress`, (e) =>{
                if(e.key.match(/[^а-яё]/ig)){
                    e.preventDefault();
                }
            });
        });
        }
        
    } catch (e) {}
};

export default checkTextInputs;