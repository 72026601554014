
const linkScrollToInfo = (linkNextArg, linkPrevArg, blockLinkArg) =>{
    try{
        const linkNext = document.querySelector(linkNextArg),
        linkPrev = document.querySelector(linkPrevArg),
        blockLink = document.querySelector(blockLinkArg);

        linkNext.addEventListener(`click`, () =>{
            // if(linkNext.classList.contains(`swiper-button-disabled`) === false){
                
            // }
            window.scrollTo({
                top: 1600,
                behavior: 'smooth',
            });
        });
        
        
        linkPrev.addEventListener(`click`, () =>{
            // if(linkPrev.classList.contains(`swiper-button-disabled`) === false){

            // }
            window.scrollTo({
                top: 1600,
                behavior: 'smooth',
            });
        });
    } catch(e){}
        
}

export default linkScrollToInfo;