const burger = (menuSelector, burgerSelector, logoSelector,headerSelector) => {
    try {
        const burgerElem = document.querySelector(burgerSelector),
        menuElem = document.querySelector(menuSelector),
        logoElem = document.querySelector(logoSelector),
        header = document.querySelector(headerSelector),
        headerHeight = parseInt(getComputedStyle(header).height) ,
        menuWrapper = menuElem.querySelector(`.menu__scroll_wrapper`),
        hashMenu = menuElem.getAttribute(`data-menu`);
        
        
    let scroll = calcScroll();
        

    burgerElem.addEventListener(`click`, () =>{
        if(!burgerElem.classList.contains(`header__burger_active`)){
            burgerElem.classList.add(`header__burger_active`);
            menuElem.classList.add(`menu_active`);
            logoElem.classList.add(`header__logo_active`);
            
            document.body.style.overflow = 'hidden';
            // document.body.style.marginRight = `${scroll}px`;
            // header.style.paddingRight = `${scroll}px`;
            if(burgerElem.classList.contains(`header__burger_active`)){
                header.style.backgroundColor = `transparent`;
                menuWrapper.addEventListener(`scroll`, () =>{
                    let menuScroll = menuWrapper.scrollTop;
                    if(menuScroll > (headerHeight / 3)){
                        header.style.transition = `opacity 0.2s`;
                        header.style.opacity = `0`;
                    } else{
                        header.style.transition = `opacity 0.2s`;
                        header.style.opacity = `1`;
                    }
                });
            }
        } else{
            burgerElem.classList.remove(`header__burger_active`)
            menuElem.classList.remove(`menu_active`);
            logoElem.classList.remove(`header__logo_active`);
            document.body.style.overflow = 'auto';
            // document.body.style.marginRight = `0px`;
            // header.style.paddingRight = `0px`;
            // header.style.position = `absolute`; 
        }
        // if(menuElem.classList.contains(`menu_active`)){
        //     console.log(`актив`)
        //     window.history.pushState(`'${hashMenu}'`, document.title, window.location + `${hashMenu}`);
        // } else if(!(menuElem.classList.contains(`menu_active`))){
        //     console.log(`не актив`)
        //     history.go(-1);
        // }
    });


    //  window.addEventListener("popstate", function(e) {
    //     e.stopPropagation()
    //     e.preventDefault()

    //     burgerElem.classList.remove(`header__burger_active`)
    //     menuElem.classList.remove(`menu_active`);
    //     logoElem.classList.remove(`header__logo_active`);
    //     document.body.style.overflow = 'auto';
    //     document.body.style.marginRight = `0px`;
    //     header.style.paddingRight = `0px`;
    //     header.style.position = `fixed`; 
    //     if (window.scrollY > 100 && header.style.backgroundColor != '#051326') {
    //         header.style.cssText = `
    //             background-color: #051326;
    //             transition: all 0.5s;
    //         `;
    //     }
    //     history.go(-1);
        
        
    //  })

    
    // closeElem.forEach((close,i) =>{
    //     close.addEventListener(`click`, () =>{
    //         if(menuElem[i].style.display = 'block' && window.screen.availWidth < 1025){
    //             menuElem[i].classList.remove(`mobile-menu__active`)
    //             document.body.style.overflow = 'auto';
    //             document.body.style.marginRight = `0px`;
    //         }
    //     });
    // })


    // burgerElem.addEventListener(`click`, () =>{
    //     if(menuElem.style.display = 'none' && window.screen.availWidth < 1024){
    //         menuElem.classList.add(`mobile-menu__active`);
    //         document.body.style.overflow = 'hidden';
    //         document.body.style.marginRight = `${scroll}px`;
    //     }
    // });

    
    // closeElem.addEventListener(`click`, () =>{
    //     if(menuElem.style.display = 'none' && window.screen.availWidth < 1024){
    //         menuElem.classList.remove(`mobile-menu__active`)
    //         document.body.style.overflow = 'auto';
    //         document.body.style.marginRight = `0px`;
    //     }
    // });

    function calcScroll(){
        let div = document.createElement(`div`);

        div.style.width = `50px`;
        div.style.height = `50px`;
        div.style.overflowY = `scroll`;
        div.style.visibility = `hidden`;
        
        document.body.appendChild(div);
        let scrollWidth = div.offsetWidth - div.clientWidth;

        div.remove()

        return scrollWidth;
    }
    // document.addEventListener('scroll', () => {
    //     if (window.scrollY > 100 && header.style.backgroundColor != '#051326') {
    //       header.style.cssText = `
    //         background-color: #051326;
    //         transition: all 0.5s;
    //       `;
    //     } else if(window.scrollY < 100 && header.style.backgroundColor != 'transparent'){
    //         header.style.cssText = `
    //         background-color: transparent;
    //         transition: all 0.5s;
    //       `;
    //     }
    // });
    } catch (e) {}
    
  
};

export default burger;