const modalsRender = (dataBase, slider) => {
    try {

        const db = dataBase,
            modal = document.querySelector(`.modal-report__block`),
            btnLinks = document.querySelectorAll(`[data-render]`),
            title = modal.querySelector(`.modal-report__title`),
            type = modal.querySelector(`.modal-report__subtitle-top`),
            imgTop = modal.querySelectorAll(`.img-top`),
            imgBot = modal.querySelectorAll(`.img-bot`),
            li = modal.querySelectorAll(`.modal-report__table_right`),
            note = modal.querySelector(`.note`),
            imgCenter = modal.querySelectorAll(`.modal-report__slider-detail__img img`),
            descrImg = modal.querySelectorAll(`.modal-report__slider-detail__text`),
            endText = modal.querySelector(`.modal-report__descr`),
            motoReport = document.querySelectorAll(`.moto-report`);
        let modalSlider = slider(),
            topSlider = modalSlider.swiperModalReportMain,
            botSlider = modalSlider.swiperModalReportSub,
            centerSlider = modalSlider.swiperCenter;

        const delSlides = () => {
            topSlider.removeAllSlides();
            botSlider.removeAllSlides();
            centerSlider.removeAllSlides();
        }
        const activeSlide = () => {
            topSlider.slideTo(0, 0);
            botSlider.slideTo(0, 0);
            centerSlider.slideTo(0, 0);
        }
        if (btnLinks.length > 0) {
            const render = () => {
                btnLinks.forEach(btn => {
                    
                    btn.addEventListener(`click`, (e) => {
                        if(btn.classList.contains(`moto-report`)){
                            let cuzov = modal.querySelector(`.cuzov`),
                                box = modal.querySelector(`.box`);
                                console.log(cuzov, box);
                            cuzov.textContent = `Тип`;
                            box.textContent = `Цилиндров`;
    
                        } else {
                            let cuzov = modal.querySelector(`.cuzov`),
                                box = modal.querySelector(`.box`);
                                console.log(cuzov, box);
                            cuzov.textContent = `Кузов`;
                            box.textContent = `Коробка`;
                        }
                        activeSlide();
                        delSlides();
                        let btnAtr = btn.getAttribute(`data-render`),
                            dateBase = db[`${btnAtr}`];
                        if (dateBase.srcTop.length != topSlider.slides.length &&
                            dateBase.srcTop.length != botSlider.slides.length) {
                            for (let i = 0; i <= dateBase.srcTop.length - 1; i++) {
                                topSlider.appendSlide(`
                                <div class="swiper-slide">
                                    <div class="modal-report__img-main">
                                        <img class="img-top" loading="lazy" src="${dateBase.srcTop[i]}" alt="${dateBase.altTop}">
                                        <div class="swiper-lazy-preloader swiper-lazy-preloader-dark"></div>
                                    </div>
                                </div>
                                `);

                                botSlider.appendSlide(`
                                <div class="swiper-slide">
                                    <div class="modal-report__img-sub">
                                        <img class="img-bot" loading="lazy" src="${dateBase.srcTop[i]}" alt="${dateBase.altTop}">
                                        <div class="swiper-lazy-preloader swiper-lazy-preloader-dark"></div>
                                    </div>
                                </div>
                                `);
                            }
                        }
                        if (dateBase.srcCenter.length != centerSlider.slides.length) {
                            for (let i = 0; i <= dateBase.srcCenter.length - 1; i++) {
                                centerSlider.appendSlide(`
                                <div class="swiper-slide">
                                    <div class="modal-report__slider-detail__img">
                                        <img loading="lazy" src="${dateBase.srcCenter[i]}" alt="${dateBase.altCenter}">
                                        <div class="swiper-lazy-preloader swiper-lazy-preloader-dark"></div>
                                    </div>
    
                                    <p class="modal-report__slider-detail__text">${dateBase.descrCenter[i]}</p>
                                </div>
                                `);
                            }
                        }

                        title.textContent = `${dateBase.title}`;
                        type.textContent = `${dateBase.type}`;

                        li.forEach((item, i) => {
                            item.textContent = `${dateBase.li[i]}`;
                        })

                        note.textContent = `${dateBase.note}`;

                        endText.textContent = `${dateBase.endText}`;
                    })

                });
            };

            render();
        }

    } catch (e) {}
};

export default modalsRender;