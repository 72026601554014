import Swiper, {Navigation,Pagination} from 'swiper/swiper-bundle.min';
const sliders = () => {
    try {
        const blockCheck = document.querySelector(`.diagnostic-moto-check`);
        
        let tabsCheckMoto = [`Рама`, `Двигатель`, `Подвеска`, `Электроника и оборудование`, `Коробка и сцепление`, `Техническое обслуживание`, `Тюнинг`, `Документы`, `Компьютерная диагностика`];
        let tabsCheck = [`Кузов`, `Двигатель`, `Коробка передач`, `Электроника и оборудование`, `Компьютерная диагностика`, `Подушки безопасности`, `Подвеска`, `Документы`, `Пробег`];
        const swiperCheck = new Swiper('.check__swiper', {
            // Optional parameters
            direction: 'vertical',
            speed: 1000,
            slidesPerView: 1,
            height: 504,
            initialSlide: 4,
            allowTouchMove: false,
            // Navigation arrows
            navigation: {
                nextEl: '.check-button-next',
                prevEl: '.check-button-prev',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 20,
                    direction: 'horizontal',
                    allowTouchMove: true,
                    initialSlide: 0,
                    speed: 400,
                    pagination: {
                        el: '.check-pagination',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 20,
                    direction: 'horizontal',
                    height: 450,
                    initialSlide: 0,
                    width: 520,
                    allowTouchMove: true,
                    speed: 400,
                    pagination: {
                        el: '.check-pagination',
                        clickable: true,
                    },
                },
                1084: {
                    height: 450,
                    pagination: {
                        el: '.swiper-pagination-dekstop',
                        clickable: true,
                        renderBullet: function (index, className) {
                            if(!blockCheck){
                                return '<span class="' + className + '">' + (tabsCheck[index]) + '</span>';
                            } else {
                                return '<span class="' + className + '">' + (tabsCheckMoto[index]) + '</span>';
                            }
                            
                        },
                    },
                },
                1261: {
                    height: 504,
                    pagination: {
                        el: '.swiper-pagination-dekstop',
                        clickable: true,
                        renderBullet: function (index, className) {
                            if(!blockCheck){
                                return '<span class="' + className + '">' + (tabsCheck[index]) + '</span>';
                            } else {
                                return '<span class="' + className + '">' + (tabsCheckMoto[index]) + '</span>';
                            }
                        },
                    },
                },
            }
        });



        const swiperReportPrem = new Swiper('.report__swiper-prem', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            slidesPerView: 'auto',
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.report__prem_next',
                prevEl: '.report__prem_prev',
            },
            // And if we need scrollbar
            scrollbar: {
                el: '.report-swiper-scrollbar-prem',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    pagination: {
                        el: '.report-pagination-prem',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 25,
                    pagination: {
                        el: '.report-pagination-prem',
                        clickable: true,
                    },
                },
                1084: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                },
                1484: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                }
            },

        });
        const swiperReportCros = new Swiper('.report__swiper-cros', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            autoHeight: 'auto',
            slidesPerView: "auto",
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.report__cros_next',
                prevEl: '.report__cros_prev',
            },
            // And if we need scrollbar
            scrollbar: {
                el: '.report-swiper-scrollbar-cros',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    pagination: {
                        el: '.report-pagination-cros',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 25,
                    pagination: {
                        el: '.report-pagination-cros',
                        clickable: true,
                    },
                },
                1084: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                },
                1484: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                }
            },
        });
        const swiperReportSed = new Swiper('.report__swiper-sed', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            autoHeight: 'auto',
            slidesPerView: "auto",
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.report__sed_next',
                prevEl: '.report__sed_prev',
            },
            // And if we need scrollbar
            scrollbar: {
                el: '.report-swiper-scrollbar-sed',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    pagination: {
                        el: '.report-pagination-sed',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 25,
                    pagination: {
                        el: '.report-pagination-sed',
                        clickable: true,
                    },
                },
                1084: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                },
                1484: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                }
            },
        });
        const swiperReportKep = new Swiper('.report__swiper-kep', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            autoHeight: 'auto',
            slidesPerView: "auto",
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.report__kep_next',
                prevEl: '.report__kep_prev',
            },
            // And if we need scrollbar
            scrollbar: {
                el: '.report-swiper-scrollbar-kep',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    pagination: {
                        el: '.report-pagination-kep',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 25,
                    pagination: {
                        el: '.report-pagination-kep',
                        clickable: true,
                    },
                },
                1084: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                },
                1484: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                }
            },
        });
        const swiperReportCom = new Swiper('.report__swiper-com', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            autoHeight: 'auto',
            slidesPerView: "auto",
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.report__com_next',
                prevEl: '.report__com_prev',
            },
            // And if we need scrollbar
            scrollbar: {
                el: '.report-swiper-scrollbar-com',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    pagination: {
                        el: '.report-pagination-com',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 25,
                    pagination: {
                        el: '.report-pagination-com',
                        clickable: true,
                    },
                },
                1084: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                },
                1484: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                }
            },
        });
        const swiperReportMot = new Swiper('.report__swiper-mot', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            autoHeight: 'auto',
            slidesPerView: "auto",
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.report__mot_next',
                prevEl: '.report__mot_prev',
            },
            // And if we need scrollbar
            scrollbar: {
                el: '.report-swiper-scrollbar-mot',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    pagination: {
                        el: '.report-pagination-mot',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 25,
                    pagination: {
                        el: '.report-pagination-mot',
                        clickable: true,
                    },
                },
                1084: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                },
                1484: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                }
            },
        });
        const swiperReportEx = new Swiper('.report__swiper-ex', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            autoHeight: 'auto',
            slidesPerView: "auto",
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.report__ex_next',
                prevEl: '.report__ex_prev',
            },
            // And if we need scrollbar
            scrollbar: {
                el: '.report-swiper-scrollbar-ex',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    pagination: {
                        el: '.report-pagination-ex',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 25,
                    pagination: {
                        el: '.report-pagination-ex',
                        clickable: true,
                    },
                },
                1084: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                },
                1484: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                }
            },
        });

        const swiperReportNew = new Swiper('.report__swiper-new', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            autoHeight: 'auto',
            slidesPerView: "auto",
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.report__new_next',
                prevEl: '.report__new_prev',
            },
            // And if we need scrollbar
            scrollbar: {
                el: '.report-swiper-scrollbar-new',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    pagination: {
                        el: '.report-pagination-new',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 25,
                    pagination: {
                        el: '.report-pagination-new',
                        clickable: true,
                    },
                },
                1084: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                },
                1484: {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                }
            },
        });


        const swiperClient = new Swiper('.client__swiper', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            autoHeight: 'auto',
            slidesPerView: "auto",
            slidesPerGroup: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.client__btn_next',
                prevEl: '.client__btn_prev',
            },
            scrollbar: {
                el: '.client-scrollbar',
            },
            breakpoints: {
                320: {
                    slidesPerView: `auto`,
                    slidesPerGroup: 1,
                    spaceBetween: 20,
                    pagination: {
                        el: '.client-pagination',
                        clickable: true,
                    },
                },
                780: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    spaceBetween: 30,
                    pagination: {
                        el: '.client-pagination',
                        clickable: true,
                    },
                },
            },
            1084: {
                slidesPerView: "auto",
                slidesPerGroup: 1,
                spaceBetween: 30,
            },
        });

        


        const swiperModalReportSub = new Swiper('.modal-report__thumb', {
            direction: 'horizontal',
            spaceBetween: 20,
            slidesPerView: 3,
            // freeMode: true,
            grabCursor: true,
            observer: true,
            observeParents: true,
            breakpoints: {
                320: {
                    slidesPerView: 2.5,
                    slidesPerGroup: 1,
                    spaceBetween: 12,
                },
                575: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                    spaceBetween: 20,
                }
            }
        });
        const swiperModalReportMain = new Swiper('.modal-report__main-slider', {
            // Optional parameters
            direction: 'horizontal',
            mousewheel: false,
            spaceBetween: 10,
            slidesPerView: 1,
            allowTouchMove: false,
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: swiperModalReportSub,
            },
        });

        const swiperCenter = new Swiper('.modal-report__slider-descr', {
            // Optional parameters
            direction: 'horizontal',
            spaceBetween: 30,
            slidesPerView: 1,
            // Navigation arrows
            navigation: {
                nextEl: '.modal-report__btn_next',
                prevEl: '.modal-report__btn_prev',
            },
            observer: true,
            observeParents: true,
            // And if we need scrollbar
            scrollbar: {
                el: '.modal-report__scrollbar',
                draggable: true,
                snapOnRelease: true,
                // Size (Length) of Scrollbar Draggable Element in px
                dragSize: 'auto',
            },
            breakpoints: {
                320: {
                    spaceBetween: 20,
                    pagination: {
                        el: '.modal-report__pagination',
                        clickable: true,
                    },
                },

            },
        });

        return {
            swiperModalReportMain,
            swiperModalReportSub,
            swiperCenter
        };


    } catch (e) {}

}


export default sliders;