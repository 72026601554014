import modalVideoDb from '../modalVideo.json';
import modals from './modals';

const modalsVideo = () => {
    try {

        const dbVideo = modalVideoDb,
            modalVideo = document.querySelector(`.modal-video-text`),
            title = modalVideo.querySelector(`.modal-video-text__title`),
            subtitle = modalVideo.querySelector(`.modal-video-text__subtitle`),
            video = modalVideo.querySelector(`video`),
            descr = modalVideo.querySelector(`.modal-video-text__descr`),
            btnLink = document.querySelectorAll(`[data-video]`),
            modalFilm = document.querySelector(`.modal-video`),
            modalFilmVideo = modalFilm.querySelector(`video`),
            modalFilmBtn = document.querySelectorAll(`[data-film]`),
            photo = document.querySelector(`.modal-video-text__photo img`),
            blockVideo = document.querySelector(`.modal-video-text__scroll`);
        if (btnLink.length > 0) {
            const renderVideo = () => {
                btnLink.forEach(item => {
                    item.addEventListener(`click`, (e) => {
                        e.preventDefault();

                        let linkAtr = item.getAttribute(`data-video`),
                            dateBase = dbVideo[`${linkAtr}`];
                        if (dateBase) {
                            title.textContent = `${dateBase.title}`;
                            subtitle.textContent = `${dateBase.subtitle}`;
                            photo.setAttribute(`src`, `${dateBase.photo}`)
                            photo.setAttribute(`alt`, `Фото клиента ${dateBase.title}`)
                            if(dateBase.video != ""){
                                blockVideo.innerHTML = `
                                <video preload="none" poster="" controls tabindex="0" autoplay muted>
                                    <source src="${dateBase.video}" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
                                </video>
                                <p class="text modal-video-text__descr">${dateBase.descr}</p>
                                `
                            } else{
                                blockVideo.innerHTML = `
                                <img class="modal-video-text__moto-photo" src="${dateBase.moto}" alt="Фотография мотоцикла ${dateBase.title}">
                                <p class="text modal-video-text__descr">${dateBase.descr}</p>
                                `
                            }

                        }


                    });
                });
            }

            const renderFilm = () => {
                modalFilmBtn.forEach(item => {
                    item.addEventListener(`click`, (e) => {
                        e.preventDefault();

                        let filmAtr = item.getAttribute(`data-film`),
                            dateBase = dbVideo[`${filmAtr}`];
                        if (dateBase) {
                            modalFilmVideo.setAttribute(`poster`, `${dateBase.poster}`);
                            modalFilmVideo.setAttribute(`src`, `${dateBase.video}`);
                        }
                    });
                });
            }
            renderFilm();
            renderVideo();
        }
    } catch (e) {}

};

export default modalsVideo;