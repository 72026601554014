const otchetiTabs = () =>{
    try {
        const header = document.querySelector(`.otcheti-all__filters`),
        tab = document.querySelectorAll(`.otcheti-all__filter`),
        content = document.querySelectorAll(`.otcheti-all-swiper`),
        btns = document.querySelectorAll(`.otcheti-all__btns`),
        activeClass = `otcheti-all__filter_active`,
        tabSelector = `.otcheti-all__filter`;
        // pagination = document.querySelectorAll(`.report__pagination_block`);
        function hideTabContent() {
            content.forEach(item => {
                item.style.cssText = `
                display: none;
            `
                item.classList.remove(`fade-in`);
            });
            tab.forEach(item => {
                item.classList.remove(activeClass);
                item.style.cursor = `pointer`;
            });
        }
        function showTabContent(i = 0) {
            content[i].style.cssText = `
                display: block;
            `
            content[i].classList.add(`fade-in`);

            tab[i].classList.add(activeClass);
        }

        hideTabContent();
        showTabContent();

        header.addEventListener(`click`, (e) => {
            const target = e.target;
            if (target &&
                (target.classList.contains(tabSelector.replace(/\./, "")) ||
                    target.parentNode.classList.contains(tabSelector.replace(/\./, "")))) {
                tab.forEach((item, i) => {
                    if (target == item || target.parentNode == item) {
                        hideTabContent();
                        showTabContent(i);
                    }
                });
            }
        });
    } catch (e) {}
    
}

export default otchetiTabs;