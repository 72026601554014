const paintBlock = () => {
    try {
        if(window.screen.width < 1024){
            const clickBlock = document.querySelectorAll(`.paint-slider__card`),
            viewBlock = document.querySelectorAll(`.paint-slider__block`),
            closeView = document.querySelectorAll(`.paint-slider__close`);
    
            clickBlock.forEach((item,i) =>{
                item.addEventListener(`click`, (e) =>{
                    viewBlock[i].classList.toggle(`paint-slider__block__active`);
                });
            }); 
        }
    } catch (e) {
        
    }
    

}

export default paintBlock;