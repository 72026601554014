import sliderPages from "./sliderPages";

const filtersSliderMoto = (tabsArg, blockArg, titleArg, activeClass) =>{
    try{
        const tabs = document.querySelectorAll(tabsArg), 
        blocks = document.querySelectorAll(blockArg),
        titleBlocks = document.querySelectorAll(titleArg);

    function deleteActiveClass(){
        tabs.forEach(item =>{
            item.classList.remove(activeClass);
        })
    }
    tabs.forEach((item,i) =>{
        item.addEventListener(`click`, (e) =>{
            deleteActiveClass();
            const target = e.target;
            item.classList.add(activeClass);

            if(e.target == tabs[0]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "a" || content[0] == "b"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[1]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "c" || content[0] == "d"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[2]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "g" || content[0] == "h"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[3]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "i" || content[0] == "k"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[4]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "m" || content[0] == "r"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[5]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "s" || content[0] == "t"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[6]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "v" || content[0] == "y"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            sliderPages();

        });
        tabs[0].click();
    })
    } catch(e){}
   

}

export default filtersSliderMoto;