const tabs = (headerSelector, tabSelector, contentSelector, activeClass, display = "block") => {
    try {
            const header = document.querySelector(headerSelector),
                tab = document.querySelectorAll(tabSelector),
                content = document.querySelectorAll(contentSelector),
                btns = document.querySelectorAll(`.report__btn`),
                bar = document.querySelectorAll(`.report__scrollbar`),
                pagination = document.querySelectorAll(`.report__pagination_block`);
                function hideTabContent() {
                    content.forEach(item => {
                        item.style.cssText = `
                        position:absolute;
                        z-index: -5;
                    `
                        item.classList.remove(`fade-in`);
                    });
                    tab.forEach(item => {
                        item.classList.remove(activeClass);
                        item.style.cursor = `pointer`;
                    });
                    btns.forEach(item => {
                        item.style.display = `none`;
                        item.classList.remove(`fade-in`);
                    });
                    bar.forEach(item => {
                        item.style.display = `none`;
                        item.classList.remove(`fade-in`);
                    });
                    pagination.forEach(item => {
                        item.style.display = `none`;
                        item.classList.remove(`fade-in`);
                    });
                }

                function showTabContent(i = 0) {
                    content[i].style.cssText = `
                        position:relative;
                        z-index: 0;
                    `
                    content[i].classList.add(`fade-in`);
                    content[i].style.display = display;

                    tab[i].classList.add(activeClass);
                    btns[i].classList.add(`fade-in`);
                    btns[i].style.display = `flex`;
                    bar[i].classList.add(`fade-in`)
                    bar[i].style.display = `block`;
                    pagination[i].classList.add(`fade-in`)
                    pagination[i].style.display = `block`;
                }

                hideTabContent();
                showTabContent();

                header.addEventListener(`click`, (e) => {
                    const target = e.target;
                    if (target &&
                        (target.classList.contains(tabSelector.replace(/\./, "")) ||
                            target.parentNode.classList.contains(tabSelector.replace(/\./, "")))) {
                        tab.forEach((item, i) => {
                            if (target == item || target.parentNode == item) {
                                hideTabContent();
                                showTabContent(i);
                            }
                        });
                    }
                });
            
    } catch (e) {}
};

export default tabs;