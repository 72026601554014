
const linkScrollTo = (linkNextArg, linkPrevArg, blockLinkArg) =>{
    try{
        const linkNext = document.querySelectorAll(linkNextArg),
        linkPrev = document.querySelectorAll(linkPrevArg),
        blockLink = document.querySelector(blockLinkArg);

        linkNext.forEach( item =>{
            item.addEventListener(`click`, () =>{
                // if(linkNext.classList.contains(`swiper-button-disabled`) === false){
                    
                // }
                window.scrollTo({
                    top: 540,
                    behavior: 'smooth',
                });
            });
        })
        
        
        linkNext.forEach( item =>{
            item.addEventListener(`click`, () =>{
                // if(linkPrev.classList.contains(`swiper-button-disabled`) === false){
    
                // }
                window.scrollTo({
                    top: 540,
                    behavior: 'smooth',
                });
            });
        })
        
    } catch(e){}
        
}

export default linkScrollTo;