import sliderPages from "./sliderPages";

const filtersSlider = (tabsArg, blockArg, titleArg, activeClass) =>{
    try{
        const tabs = document.querySelectorAll(tabsArg), 
        blocks = document.querySelectorAll(blockArg),
        titleBlocks = document.querySelectorAll(titleArg);

    function deleteActiveClass(){
        tabs.forEach(item =>{
            item.classList.remove(activeClass);
        })
    }
    tabs.forEach((item,i) =>{
        item.addEventListener(`click`, (e) =>{
            deleteActiveClass();
            const target = e.target;
            item.classList.add(activeClass);

            if(e.target == tabs[0]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "a" || content[0] == "b"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[1]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "c" || content[0] == "d"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[2]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "f" || content[0] == "g"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[3]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "h" || content[0] == "i"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[4]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "j" || content[0] == "l"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[5]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "m" || content[0] == "n"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[6]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "o" || content[0] == "p"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[7]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "r" || content[0] == "s"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            if(e.target == tabs[8]){
                titleBlocks.forEach((item,j) =>{
                    let content = titleBlocks[j].textContent.replace(/[0-9]/g, '').replace(/\s+/g, '').toLowerCase();

                    if(content[0] == "t" || content[0] == "v"){
                        blocks[j].style.display = `block`;
                    } else {
                        blocks[j].style.display = `none`;
                    }
                });
            }
            sliderPages();

        });
        tabs[0].click();
    })
    } catch(e){}
   

}

export default filtersSlider;