import Swiper, {Navigation,Pagination} from 'swiper/swiper-bundle.min';

const sliderPages = () =>{
    const ReviewsAll = new Swiper('.reviews-all__swiper', {
        direction: 'horizontal',
        spaceBetween: 30,
        slidesPerView: 1,
        slidesPerGroup: 1,
        navigation: {
            nextEl: '.reviews-all__btn_next',
            prevEl: '.reviews-all__btn_prev',
        },
    });

    const OtchetiAll = new Swiper('.otcheti-all-swiper', {
        direction: 'horizontal',
        autoHeight: true,
        slidesPerView: 1,
        navigation: {
            nextEl: '.otcheti-all__next',
            prevEl: '.otcheti-all__prev',
        },
    });
    const OtchetiAllAuto = new Swiper('.otcheti-all-auto__swiper', {
        // Optional parameters
        direction: 'horizontal',
        spaceBetween: 30,
        slidesPerView: 1,
        // Navigation arrows
        navigation: {
            nextEl: '.otcheti-all-auto__next',
            prevEl: '.otcheti-all-auto__prev',
        },
        // And if we need scrollbar
        scrollbar: {
            el: '.otcheti-all-auto__scrollbar',
        },
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        breakpoints: {
            320: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                pagination: {
                    el: '.otcheti-all-auto__pagination',
                    clickable: true,
                },
            },
            780: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                spaceBetween: 25,
                pagination: {
                    el: '.otcheti-all-auto__pagination',
                    clickable: true,
                },
            },
            1084: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                spaceBetween: 30,
            },
            1484: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                spaceBetween: 30,
            }
        },

    });
    
    const OtchetiAllMoto = new Swiper('.otcheti-all-moto__swiper', {
        // Optional parameters
        direction: 'horizontal',
        spaceBetween: 30,
        slidesPerView: 1,
        // Navigation arrows
        navigation: {
            nextEl: '.otcheti-all-moto__next',
            prevEl: '.otcheti-all-moto__prev',
        },
        // And if we need scrollbar
        scrollbar: {
            el: '.otcheti-all-moto__scrollbar',
        },
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        breakpoints: {
            320: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                pagination: {
                    el: '.otcheti-all-moto__pagination',
                    clickable: true,
                },
            },
            780: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                spaceBetween: 25,
                pagination: {
                    el: '.otcheti-all-moto__pagination',
                    clickable: true,
                },
            },
            1084: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                spaceBetween: 30,
            },
            1484: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,
                spaceBetween: 30,
            }
        },

    });

    const otchetiDelThumb = new Swiper('.otcheti-del-main__slider_sub', {
        direction: 'horizontal',
        spaceBetween: 0,
        slidesPerView: 9,
        allowTouchMove: false,
        grabCursor: false,
        observer: true,
        observeParents: true,
        breakpoints: {
            320: {
                slidesPerView: 2.3,
                allowTouchMove: true,
                grabCursor: true,
            },
            577: {
                slidesPerView: 2.4,
                allowTouchMove: true,
                grabCursor: true,
            },
            780: {
                spaceBetween: 0,
                slidesPerView: 9,
                allowTouchMove: false,
                grabCursor: false,
                observer: true,
                observeParents: true,
            },
        }
    });
    const otchetiDelMain = new Swiper('.otcheti-del-main__slider_main', {
        // Optional parameters
        direction: 'horizontal',
        mousewheel: false,
        slidesPerView: 1,
        allowTouchMove: false,
        observer: true,
        observeParents: true,
        thumbs: {
            swiper: otchetiDelThumb,
        },
    });

    const viewSwiper = new Swiper('.view__slider', {
        // Optional parameters
        autoHeight: true,
        direction: 'horizontal',
        spaceBetween: 30,
        slidesPerView: 2.5,
        // Navigation arrows
        navigation: {
            nextEl: '.view-button__next',
            prevEl: '.view-button__prev',
        },
        observer: true,
        observeParents: true,
        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar-view',
            draggable: true,
            snapOnRelease: true,
            // Size (Length) of Scrollbar Draggable Element in px
            dragSize: 'auto',
        },
        breakpoints: {
            320: {
                spaceBetween: 20,
                pagination: {
                    el: '.swiper-pagination-view',
                    clickable: true,
                },
                slidesPerView: 1,
            },
            720: {
                scrollbar: {
                    el: '.swiper-scrollbar-view',
                    draggable: true,
                    snapOnRelease: true,
                    // Size (Length) of Scrollbar Draggable Element in px
                    dragSize: 'auto',
                },
            }
        },
    });

    const viewSwiperVessel = new Swiper('.view__slider-vessel', {
        // Optional parameters
        autoHeight: true,
        direction: 'horizontal',
        spaceBetween: 30,
        slidesPerView: 2.5,
        // Navigation arrows
        navigation: {
            nextEl: '.view-button-vessel__next',
            prevEl: '.view-button-vessel__prev',
        },
        observer: true,
        observeParents: true,
        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar-view',
            draggable: true,
            snapOnRelease: true,
            // Size (Length) of Scrollbar Draggable Element in px
            dragSize: 'auto',
        },
        breakpoints: {
            320: {
                spaceBetween: 20,
                pagination: {
                    el: '.swiper-pagination-view-vessel',
                    clickable: true,
                },
                slidesPerView: 1,
            },
            720: {
                scrollbar: {
                    el: '.swiper-scrollbar-view-vessel',
                    draggable: true,
                    snapOnRelease: true,
                    // Size (Length) of Scrollbar Draggable Element in px
                    dragSize: 'auto',
                },
            }
        },
    });
    const viewSwiperCabin = new Swiper('.view__slider-cabin', {
        // Optional parameters
        autoHeight: true,
        direction: 'horizontal',
        spaceBetween: 30,
        slidesPerView: 2.5,
        // Navigation arrows
        navigation: {
            nextEl: '.view-button-cabin__next',
            prevEl: '.view-button-cabin__prev',
        },
        observer: true,
        observeParents: true,
        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar-view',
            draggable: true,
            snapOnRelease: true,
            // Size (Length) of Scrollbar Draggable Element in px
            dragSize: 'auto',
        },
        breakpoints: {
            320: {
                spaceBetween: 20,
                pagination: {
                    el: '.swiper-pagination-view-cabin',
                    clickable: true,
                },
                slidesPerView: 1,
            },
            720: {
                scrollbar: {
                    el: '.swiper-scrollbar-view-cabin',
                    draggable: true,
                    snapOnRelease: true,
                    // Size (Length) of Scrollbar Draggable Element in px
                    dragSize: 'auto',
                },
            }
        },
    });
    // OtchetiAllMoto.update();
    // OtchetiAllAuto.update();
}

export default sliderPages;