import modals from "./modals";

const formContact = (formClass) =>{
    try {
        try {
            const form = document.querySelectorAll(formClass),
                inputs = document.querySelectorAll(`${formClass} input`);
        
            const clearInputs = () => {
                inputs.forEach(item => {
                    item.value = '';
                });
            };
        
            form.forEach(item => {
                item.addEventListener('submit', (event) => {
                    event.preventDefault();
                    
                    const modalCard = document.querySelector(`.modal-card`),
                        modalReport = document.querySelector(`.modal-report`),
                        inputItem = item.querySelectorAll(`input`);
                    let code = document.querySelectorAll('.code');
                    code.forEach(cod =>{
                        cod.value = 'NOSPAM'; 
                    });

                    if(inputItem[0].value.length > 0){
                        console.log("Отправка запроса");
                        let req = new XMLHttpRequest();
                        req.open('POST', './contacts.php', true);
                        // console.log(req);
                        req.onload = function() {
                            if (req.status >= 200 && req.status < 400) {
                                let json = JSON.parse(req.response);
                                console.log(json);
                                
                                // ЗДЕСЬ УКАЗЫВАЕМ ДЕЙСТВИЯ В СЛУЧАЕ УСПЕХА ИЛИ НЕУДАЧИ
                                if (json.result == "success") {
                                    if(getComputedStyle(modalCard).display === `block` || getComputedStyle(modalReport).display === `block`){
                                        // history.go(-1);
                                        item.querySelector(`button`).setAttribute(`data-ok`, `ok`);
                                        modals(`[data-ok]`, `.modal-ok`, `.modal-ok__close`);
                                        item.querySelector(`button`).click();
                                        item.querySelector(`button`).removeAttribute(`data-ok`);
                                        clearInputs(); 
                                    } else{
                                        item.querySelector(`button`).setAttribute(`data-ok`, `ok`);
                                        modals(`[data-ok]`, `.modal-ok`, `.modal-ok__close`);
                                        item.querySelector(`button`).click();
                                        item.querySelector(`button`).removeAttribute(`data-ok`);
                                        clearInputs();
                                    }
                                    
                                    
                                } else {
                                    if(getComputedStyle(modalCard).display === `block` || getComputedStyle(modalReport).display === `block`){
                                        // history.go(-1);
                                        item.querySelector(`button`).setAttribute(`data-fail`, `fail`);
                                        modals(`[data-fail]`, `.modal-fail`, `.modal-fail__close`);
                                        item.querySelector(`button`).click();
                                        item.querySelector(`button`).removeAttribute(`data-fail`);
                                        clearInputs();
                                    } else{
                                        item.querySelector(`button`).setAttribute(`data-fail`, `fail`);
                                        modals(`[data-fail]`, `.modal-fail`, `.modal-fail__close`);
                                        item.querySelector(`button`).click();
                                        item.querySelector(`button`).removeAttribute(`data-fail`);
                                        clearInputs();
                                    }
                                    item.querySelector(`button`).setAttribute(`data-fail`, `fail`);
                                    modals(`[data-fail]`, `.modal-fail`, `.modal-fail__close`);
                                    item.querySelector(`button`).click();
                                    item.querySelector(`button`).removeAttribute(`data-fail`);
                                    clearInputs();
                                }
                            // Если не удалось связаться с php файлом
                            } else {
                                item.querySelector(`button`).setAttribute(`data-fail`, `fail`);
                                modals(`[data-fail]`, `.modal-fail`, `.modal-fail__close`);
                                item.querySelector(`button`).click();
                                item.querySelector(`button`).removeAttribute(`data-fail`);
                                clearInputs();
                            }}; 
                    
                            // Если не удалось отправить запрос. Стоит блок на хостинге
                            req.onerror = function() {
                                item.querySelector(`button`).setAttribute(`data-fail`, `fail`);
                                modals(`[data-fail]`, `.modal-fail`, `.modal-fail__close`);
                                item.querySelector(`button`).click();
                                item.querySelector(`button`).removeAttribute(`data-fail`);
                                clearInputs();
                            };
                            req.send(new FormData(event.target));
                    } else{
                        const textForm = document.createElement(`div`);
                        item.style.position = `relative`;
                        textForm.style.cssText = `
                            position: absolute;
                            width:100%;
                            bottom:-100px;
                            text-align: center;
                            margin-top: 5px;
                            color: #FF1616;
                            background: #F7F7F8;
                            border-radius: 32px;
                            z-index:25;
                            padding: 10px 0;
                        `;
                        textForm.classList.add(`text`, `fade-in`);
                        textForm.textContent = `Пожалуйста заполните ваше имя и email`;
                        item.append(textForm);
                        setTimeout(() => {
                            textForm.classList.remove(`fade-in`);
                            textForm.classList.add(`fade-out`);
                        },3000)
                    }
                });
            });
            } catch (e) {}
    } catch (e) {}
}

export default formContact;