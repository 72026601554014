const animationVisible = () => {
    try {
    // функция определяет нахождение элемента в области видимости
    // если элемент видно - возвращает true
    // если элемент невидно - возвращает false
    function isOnVisibleSpace(element) {
        const bodyHeight = window.innerHeight,
            elemRect = element.getBoundingClientRect(),
            offset = elemRect.top; // - bodyRect.top;
        if (offset < 0) return false;
        if (offset > bodyHeight) return false;
        return true;
    }

    // глобальный объект с элементами, для которых отслеживаем их положение в зоне видимости
    const listenedElements = [];

    // обработчик события прокрутки экрана. Проверяет все элементы добавленные в listenedElements 
    // на предмет попадания(выпадения) в зону видимости

    window.onscroll = function () {
        listenedElements.forEach(item => {
            // проверяем находится ли элемент в зоне видимости
            const result = isOnVisibleSpace(item.el);

            // если элемент находился в зоне видимости и вышел из нее
            // вызываем обработчик выпадения из зоны видимости
            if (item.el.isOnVisibleSpace && !result) {
                item.el.isOnVisibleSpace = false;
                item.outVisibleSpace(item.el);
                return;
            }
            // если элемент находился вне зоны видимости и вошел в нее
            // вызываем обработчик попадания в зону видимости
            if (!item.el.isOnVisibleSpace && result) {
                item.el.isOnVisibleSpace = true;
                item.inVisibleSpace(item.el);
                return;
            }
        });
    }



    // функция устанавливает обработчики событий 
    // появления элемента в зоне видимости и
    // выхода из нее
    function onVisibleSpaceListener(elementId, cbIn, cbOut) {
        // получаем ссылку на объект элемента
        const els = document.querySelectorAll(elementId);
        els.forEach(el => {
            listenedElements.push({
                el: el,
                inVisibleSpace: cbIn,
                outVisibleSpace: cbOut
            });
        });
        // добавляем элемент и обработчики событий 
        // в массив отслеживаемых элементов
    }

    // устанавливаем обработчики для элемента "video"

    onVisibleSpaceListener(".animation",

        el => {
            // функция вызываемая при попадании элемента в зону видимости
            // тут вставляем код запуска анимации
            el.classList.add(`animation-default`);
        },

        el => {
            // функция вызываемая при выпадении элемента из зоны видимости
            // тут вставляем код остановки анимации
            el.classList.add(`animation-default`);
        }
    );


    onVisibleSpaceListener(".animation-del",

        el => {
            // функция вызываемая при попадании элемента в зону видимости
            // тут вставляем код запуска анимации
            el.classList.add(`animation-delay`);
        },

        el => {
            // функция вызываемая при выпадении элемента из зоны видимости
            // тут вставляем код остановки анимации
        }
    );
    
    // onVisibleSpaceListener(".marque",

    //     el => {
    //         Marquee3k.toggleAll();
    //         console.log(`Запустилось`);
    //     },

    //     el => {
    //         Marquee3k.toggleAll();
    //         console.log(`Остановилось`);
    //     }
    // );

    } catch (e) {}
}




export default animationVisible;