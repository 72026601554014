const modals = (triggerSelector, modalSelector, closeSelector, destroy = false) => {
    try {
        const trigger = document.querySelectorAll(triggerSelector),
            modal = document.querySelector(modalSelector),
            closes = document.querySelectorAll(closeSelector),
            modals = document.querySelectorAll(`[data-modal]`),
            scroll = calcScroll(),
            hashModal = modal.getAttribute(`data-modal`);
            trigger.forEach((item, i) => {
                item.addEventListener(`click`, (e) => {
                    e.preventDefault();

                    if (destroy) {
                        item.remove();
                    }

                    // modal.classList.remove(`fade-out`);
                    modal.classList.add(`fade-in`);
                    if (modal.classList.contains(`modal-report`)) {
                        modal.style.cssText = `
                            opacity:1;
                            z-index:10;
                            transition: all 0.3s;
                        `
                    } else if (modal.classList.contains(`modal-card`)) {
                        modal.style.cssText = `
                            opacity:1;
                            z-index:10;
                        `
                    } else if(modal.classList.contains(`modal-question`)){
                        modal.style.cssText = `
                            opacity:1;
                            z-index:10;
                        `
                    } else {
                        modal.style.cssText = `
                            opacity:1;
                            z-index:20;
                        `
                    }

                    document.body.style.overflow = 'hidden';
                    // document.body.style.marginRight = `${scroll}px`;

                });
            });
            // window.addEventListener("popstate", function(e) {
            //     e.stopPropagation()
            //     e.preventDefault()

            //     if(window.history.state === null){
            //         modal.classList.add(`fade-out`);
            //         modal.style.display = 'none';
            //         modal.classList.remove(`fade-in`);
            //         document.body.style.overflow = 'auto';
            //         document.body.style.marginRight = `0px`;
            //     }
            // })


            closes.forEach(close => {
                close.addEventListener(`click`, (e) => {
                    // e.preventDefault();


                    if (modal.classList.contains(`modal-report`)) {
                        modal.style.transition = `all 0s`;
                    } else {
                        // modal.classList.add(`fade-out`);
                    }
                    modal.style.cssText = `
                    opacity:0;
                    z-index:-10;
                    `;
                    modal.classList.remove(`fade-in`);
                    if(document.querySelector(`.modal-report`)){
                        if ((getComputedStyle(document.querySelector(`.modal-report`)).opacity === `1`) &&
                        (getComputedStyle(document.querySelector(`.modal-fail`)).opacity === `1`)) {
                        document.body.style.overflow = 'hidden';
                        } else if ((getComputedStyle(document.querySelector(`.modal-report`)).opacity === `1`) &&
                            (getComputedStyle(document.querySelector(`.modal-ok`)).opacity === `1`)) {
                            document.body.style.overflow = 'hidden';
                        } else if ((getComputedStyle(document.querySelector(`.modal-card`)).opacity === `1`) &&
                            (getComputedStyle(document.querySelector(`.modal-fail`)).opacity === `1`)) {
                            document.body.style.overflow = 'hidden';
                        } else if ((getComputedStyle(document.querySelector(`.modal-card`)).opacity === `1`) &&
                            (getComputedStyle(document.querySelector(`.modal-ok`)).opacity === `1`)) {
                            document.body.style.overflow = 'hidden';
                        } else {
                            document.body.style.overflow = 'auto';
                        }
                    } else{
                        document.body.style.overflow = 'auto';
                    }
                    
                    if(modal.classList.contains(`modal-video`)){
                        let videoModals = modal.querySelector(`video`);

                        videoModals.pause();
                    }
                    if(modal.classList.contains(`modal-video-text`)){
                        let videoModals = modal.querySelector(`video`);

                        videoModals.pause();
                    }
                    // document.body.style.marginRight = `0px`;

                    // history.go(-1);          
                });
            });

            function showModalBindTime(selector, time) {
                let display;

                document.querySelectorAll(`[data-modal]`).forEach(item => {
                    if (getComputedStyle(item).display !== `none`) {

                        display = "block"
                    }
                    if (!display) {
                        setTimeout(() => {
                            scroll = calcScroll();
                            document.body.style.marginRight = `${scroll}px`;
                            document.querySelector(selector).style.display = `block`;
                            document.body.style.overflow = 'hidden';
                        }, time);
                    }
                });
            };


            function calcScroll() {
                let div = document.createElement(`div`);

                div.style.width = `50px`;
                div.style.height = `50px`;
                div.style.overflowY = `scroll`;
                div.style.visibility = `hidden`;

                document.body.appendChild(div);
                let scrollWidth = div.offsetWidth - div.clientWidth;

                div.remove()

                return scrollWidth;
            }

            function openByScroll(selector) {
                window.addEventListener(`scroll`, () => {
                    let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
                    if (window.pageYOffset + document.documentElement.clientHeight >= scrollHeight) {
                        document.querySelector(selector).click();
                    }
                });
            }
            // showModalBindTime(`.modal-card` , 60000);
    } catch (e) {}

};

export default modals;