const accardion = (triggerSelector, blockSelector, triggerClass, blockClass, svgSelector) => {
    try {
        const btns = document.querySelectorAll(triggerSelector),
            blocks = document.querySelectorAll(blockSelector),
            svgIcon = document.querySelectorAll(svgSelector);

        btns.forEach((btn, i) => {
            btn.addEventListener(`click`, function () {
                if (this.classList.contains(`about-descr__accardion_block`)) {
                    btns.forEach((btn, j) => {
                        btn.classList.remove(`${triggerClass}`);
                        blocks[j].classList.remove(`${blockClass}`, `fade-in`);
                    });

                }
                this.classList.toggle(`${triggerClass}`);
                blocks[i].classList.toggle(`${blockClass}`);
                blocks[i].classList.toggle(`fade-in`);
                if (svgIcon.length > 0) {
                    svgIcon[i].classList.toggle(`${svgSelector}`);
                }
            });
        });


    } catch (e) {}

};

export default accardion;