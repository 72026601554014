const menuLink = () => {
    try {
        const links = document.querySelectorAll(`.menu__link_color_active`);
        links.forEach(item => {
            if(window.location.pathname != `/`){
                let location = window.location.href,
                link = item.href,
                result = location.match(link);
                if (result != null) {
                    item.classList.add(`menu__link_active`);
                } else if (location != link) {
                    item.classList.remove(`menu__link_active`);
                } 
            } 
        });

    } catch (e) {}

}

export default menuLink