const animationTitle = () => {
    try {
        function animationSelector(selector, subSelector) {
            let text = document.querySelector(selector).textContent,
                content = document.querySelector(selector);

            document.querySelector(selector).textContent = ``;
            for (let i in [...text]) {
                content.style.opacity = `1`;
                let letter = document.createElement('span')
                letter.textContent = [...text][i]
                if (letter.textContent.match(/\s/)) {
                    letter.style.margin = 'auto 2px'
                }
                if (i > 16 && i < 21) {
                    letter.style.color = `#FF1616`;
                }
                letter.style.animationDelay = i / 10 + 's'
                content.appendChild(letter);
            }
        }
        if (document.querySelector(`.hero__title`)) {
            animationSelector(`.hero__title`);
            setTimeout(() => {
                animationSelector(`.hero__title_subtitle`);
            }, 2100)
        }

    } catch (e) {}


}

export default animationTitle;