const priceAccordion = (title, ul, svg) =>{
    try{
        const block = document.querySelector(title),
        list = document.querySelector(ul),
        arrow = document.querySelector(svg);
    
    block.addEventListener(`click` , (e) =>{
        list.classList.toggle(`price-auto__list__active`);
        arrow.classList.toggle(`title-arrow__active`);
        });
    if(window.screen.width < 780 && list.classList.contains(`price-auto__list__active`) === true){
        list.classList.remove(`price-auto__list__active`);
        arrow.classList.remove(`title-arrow__active`);
    }
    } catch(e){}

}
export default priceAccordion;