//Отключение ховер эффектов при скролее
import hoverScroll from "./modules/hoverScroll";
//Меню и бургер
import burger from "./modules/burger";
//Скролл по якорям
import scrolling from "./modules/scrolling";
//Табы
import tabs from "./modules/tabs";
//Аккардион
import accardion from "./modules/accardion";
//Слайдеры
import sliders from "./modules/sliders";
import sliderPages from "./modules/sliderPages";
//Модульные окна
import modals from "./modules/modals.js";
//Проверка input в forme
import checkTextInputs from './modules/checkTextInputs';
//Маска телефона input в forme
import mask from './modules/mask';
//Обработчик формы
import forms from './modules/forms.js';
import formContact from "./modules/formsContact";
//Рендер модульного окна modal-render
import modalDate from './modalCard.json';
import modalsRender from "./modules/modalsRender";
//Рендер модульного окна modal-video
import modalsVideo from './modules/modalsVideo';
//Анимация текста
import animationVisible from "./modules/animationVisible";
import animationTitle from "./modules/animationTitle";
//Активное меню на страницах
import menuLink from "./modules/menuLink.js";
//Скролл вверх слайдера на мобильных
import linkScrollTo from "./modules/linkScrollTo";
import linkScrollToInfo from "./modules/linkScrollToInfo";

import otchetiTabs from "./modules/otchetiTabs";

import linkScroll from "./modules/linksScroll";

import priceAccordion from "./modules/priceAccordion";

import paintBlock from "./modules/paintBlock";

import filtersSlider from "./modules/filtersSlider";

import filtersSliderMoto from "./modules/filterSliderMoto";

document.addEventListener(`DOMContentLoaded`, () => {
    hoverScroll();
    burger(`.menu`, `.header__burger`, `.header__logo`, `.header`);

    // checkTextInputs(`[name = "name"]`);
    mask(`[name = "phone"]`);
    forms('.form-one');
    formContact(`.form-two`);
    menuLink();
    // scrolling();

    accardion(`.price__card_arrow`, `.price__content`, `price__card_arrow-active`, `price__content_active`);
    accardion(`.question__accardion_block`, `.question__accardion_content`, `question__accardion_block_active`, `question__accardion_content_active`, `question__accardion_plus`);
    modals(`.price__card_btn`, `.modal-card`, `.modal-card__close`);


    accardion(`.about-descr__accardion_block`, `.about-descr__accardion_content`, `about-descr__accardion_block_active`, `about-descr__accardion_content_active`, `about-descr__accardion_plus`);
    sliders();
    sliderPages();

    tabs(`.report__tabs`, `.report__tab`, `.report__slider`, `report__tab_active`);
    modals(`.report .modal-report__script`, `.modal-report`, `.modal-report__close`);
    modalsRender(modalDate, sliders);
    // modalsRender(modalDate, sliderPages);

    modals(`.modal-btn-card`, `.modal-card`, `.modal-card__close`);
    modals(`.modal-btn-question`, `.modal-question`, `.modal-question__close`);



    modals(`.client__card_img`, `.modal-video`, `.modal-video__close`);
    modals(`.client__card_btn`, `.modal-video-text`, `.modal-video-text__close`);
    modals(`.moto-btn`, `.modal-video-text`, `.modal-video-text__close`);
    modalsVideo();

    animationVisible();

    animationTitle();

    linkScrollTo(`.reviews-all__btn_next`,`.reviews-all__btn_prev`, `.link-to-reviews`);
    linkScrollTo(`.otcheti-all__next`,`.otcheti-all__prev`, `.otcheti-all`);
    linkScrollToInfo(`.link-next`,`.link-prev`, `.link-scroll-to`);

    linkScroll();

    priceAccordion(`.auto-one`, `.list-one`, `.auto-one .title-arrow`);
    priceAccordion(`.auto-two`, `.list-two`, `.auto-two .title-arrow`);
    priceAccordion(`.moto-one`, `.list-three`, `.moto-one .title-arrow`);
    priceAccordion(`.moto-two`, `.list-four`, `.moto-two .title-arrow`);

    paintBlock();

    otchetiTabs();

    filtersSlider(`#otcheti-all-auto .otcheti-all-auto__filter`, `.otcheti-all-auto__swiper .swiper-slide`, `.otcheti-all-auto__swiper .report__card_title`, `otcheti-all-auto__filter_active`);

    filtersSliderMoto(`#otcheti-all-moto .otcheti-all-auto__filter`, `.otcheti-all-moto__swiper .swiper-slide`, `.otcheti-all-moto__swiper .report__card_title`, `otcheti-all-auto__filter_active`);

    filtersSlider(`.paint-slider .otcheti-all-auto__filter`, `.paint-slider .otcheti-all-auto__swiper .swiper-slide`, `.paint-slider .paint-slider__mark`, `otcheti-all-auto__filter_active`);
});